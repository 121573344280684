import { INote } from "lib/types/note";
import { rest } from "msw";

import { notes } from "../data/notes";

const getClientNotesAll = rest.get(
  "/clients/:clientId/notes",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(notes));
  }
);
const getClientNoteById = rest.get(
  "/clients/:clientId/notes/:noteId",
  async (_, res, ctx) => {
    const response = notes[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const clientPostNote = rest.post(
  "/clients/:clientId/notes/create",
  async (_, res, ctx) => {
    const response: INote = notes[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const clientEditNote = rest.post(
  "/clients/:clientId/notes/edit",
  async (_, res, ctx) => {
    const response: INote = notes[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const clientDeleteNote = rest.post(
  "/clients/delete_note/:noteId",
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ succes: true }));
  }
);

const getStaffNotesAll = rest.get(
  "/staff/:staffId/notes",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(notes.slice(4)));
  }
);
const getStaffNoteById = rest.get(
  "/staff/:staffId/notes/:noteId",
  async (_, res, ctx) => {
    const response = notes[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const staffPostNote = rest.post(
  "/staff/:staffId/notes/create",
  async (_, res, ctx) => {
    const response: INote = notes[0];

    return res(ctx.status(200), ctx.json(response));
  }
);
const staffEditNote = rest.post(
  "/staff/:staffId/notes/edit",
  async (_, res, ctx) => {
    const response: INote = notes[0];

    return res(ctx.status(200), ctx.json(response));
  }
);
const staffDeleteNote = rest.post(
  "/staff/delete_note/:noteId",
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ succes: true }));
  }
);

export const notesHandlers = [
  getClientNotesAll,
  getClientNoteById,
  clientPostNote,
  clientEditNote,
  clientDeleteNote,
  getStaffNotesAll,
  getStaffNoteById,
  staffPostNote,
  staffEditNote,
  staffDeleteNote,
];
