import { rest } from "msw";

import {
  ILoginResponse,
  ILogoutResponse,
  ILoginErrorResponse,
} from "lib/types/auth";
import {
  adminUser,
  clientUser,
  client_token,
  admin_token,
  staffUser,
  staff_token,
} from "../data/auth";

const login = rest.post("/account/login", async (req: any, res, ctx) => {
  const { email, password } = req.body;

  if (password === "staff") {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: staffUser,
        token: staff_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }

  if (password === "admin") {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: adminUser,
        token: admin_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }

  if (password === "client") {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: { ...clientUser, email },
        token: client_token,
      },
    };

    return res(ctx.status(200), ctx.json(response));
  }

  const errorResponse: ILoginErrorResponse = {
    error: "Login error message",
    success: false,
  };
  return res(ctx.status(401), ctx.json(errorResponse));
});

const logout = rest.get("/account/logout", async (req: any, res, ctx) => {
  const response: ILogoutResponse = {
    success: true,
    message: "Logged out",
  };
  return res(ctx.status(200), ctx.json(response));
});

const reset = rest.post(
  "/account/request-password-reset",
  async (req: any, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        msg: "Please check your emails",
      })
    );
  }
);

const refresh = rest.get("/account/reauth", async (req: any, res, ctx) => {
  const authHeader = req?.headers?._headers?.authorization;

  if (authHeader.includes(staff_token)) {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: staffUser,
        token: staff_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }

  if (authHeader.includes(admin_token)) {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: adminUser,
        token: admin_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }

  if (authHeader.includes(client_token)) {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: clientUser,
        token: client_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }

  return res(
    ctx.status(401),
    ctx.json({
      error: "Unable to log in automatically",
    })
  );
});

const accountUpdate = rest.post(
  "/account/update-account",
  async (_, res, ctx) => {
    const response: ILoginResponse = {
      success: true,
      message: "success",
      data: {
        user: adminUser,
        token: admin_token,
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

export const authHandlers = [login, logout, refresh, reset, accountUpdate];
