import { rest, RestRequest, RestContext } from "msw";

import { consultants } from "../data/consultants";
import { IApiConsultantTableResponse } from "lib/types/consultant";
import { FormErrorResponse } from "lib/types";

const getConsultantsAll = rest.post(
  "/consultants/table",
  async (_: RestRequest, res: any, ctx: RestContext) => {
    const response: IApiConsultantTableResponse = {
      data: consultants,
      current_page: 1,
      total: 1,
      first_page_url: "/consultants/table",
      from: 1,
      last_page: 1,
      last_page_url: "/consultants/table",
      per_page: 15,
      to: 2,
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

const inviteConsultant = rest.post(
  "/consultants/create",
  async (req: any, res, ctx) => {
    const { email } = req.body;
    if (email === "duplicate@email.com") {
      const errorResponse: FormErrorResponse = {
        errors: { email: ["Duplicate email"] },
        message: "",
      };
      return res(ctx.status(422), ctx.json(errorResponse));
    }
    return res(
      ctx.status(200),
      ctx.json({
        id: 1,
      })
    );
  }
);

const deleteConsultant = rest.post(
  "/consultant/delete/:consultantId",
  async (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
      })
    );
  }
);

export const consultantHandlers = [
  getConsultantsAll,
  inviteConsultant,
  deleteConsultant,
];
