import { rest } from "msw";

import { clients, clientParents } from "../data/clients";
import { IApiClientTableResponse, IClientReport } from "lib/types/client";
import { FormErrorResponse } from "lib/types";

const getAllClients = rest.post("/clients/all", async (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(clients));
});

const getClientsPaginated = rest.post("/clients/table", async (_, res, ctx) => {
  const response: IApiClientTableResponse = {
    data: clients,
    current_page: 1,
    total: 1,
    first_page_url: "/clients/table",
    from: 1,
    last_page: 1,
    last_page_url: "/clients/table",
    per_page: 15,
    to: 2,
  };
  return res(ctx.status(200), ctx.json(response));
});

const getClient = rest.get("/clients/:id", async (req, res, ctx) => {
  const { id } = req.params;
  return res(
    ctx.status(200),
    ctx.json({
      ...clients[0],
      id,
    })
  );
});

const postClient = rest.post("/clients/create", async (req: any, res, ctx) => {
  const email = req?.body?.email;
  if (email === "duplicate@email.com") {
    const errorResponse: FormErrorResponse = {
      errors: { email: ["Duplicate email"] },
      message: "",
    };
    return res(ctx.status(400), ctx.json(errorResponse));
  }

  return res(
    ctx.status(200),
    ctx.json({
      id: 1,
    })
  );
});

const updateClient = rest.post("/clients/:id/edit", async (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      data: "Client successfully updated",
    })
  );
});

const deleteClient = rest.post(
  "/clients/delete/:clientId",
  async (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
      })
    );
  }
);

const clientReport = rest.get(
  "/clients/:clientId/reports",
  async (_, res, ctx) => {
    const report: IClientReport = {
      data: {
        shifts_accepted: 76,
        shifts_cancelled: 19,
        shifts_last_minute: 42,
        notes: [],
      },
    };
    return res(ctx.status(200), ctx.json(report));
  }
);

const getAllClientParents = rest.post(
  "/clients/all_parents",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(clientParents));
  }
);

export const clientHandlers = [
  getClientsPaginated,
  getAllClients,
  getClient,
  postClient,
  updateClient,
  deleteClient,
  clientReport,
  getAllClientParents,
];
