import axios from "axios";
import React, { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/toast";

import type {
  ICredentials,
  IUser,
  TAuthStatus,
  ILogoutResponse,
  ILoginErrorResponse,
} from "lib/types/auth";

const AUTH_TOKEN = "__sh_auth_token__";

interface IApiResetPassword {
  uuid: string;
  token: string;
  newPassword: string;
}

interface ISetSession {
  user: IUser;
  token: string;
}

type Me = IUser | null;

const AuthContext = React.createContext<{
  user: IUser | null;
  status: TAuthStatus;
  logout: () => void;
  requestPasswordReset: (email: string) => Promise<any>;
  passwordReset: (props: IApiResetPassword) => Promise<void>;
  inviteSignup: (props: IApiResetPassword) => Promise<void>;
  login: (cred: ICredentials) => Promise<any>;
  setSession: (cred: ISetSession) => void;
}>({
  user: null,
  status: "not_authenticated",
  logout: () => new Promise(() => {}),
  requestPasswordReset: () => new Promise(() => {}),
  passwordReset: () => new Promise(() => {}),
  inviteSignup: () => new Promise(() => {}),
  login: () => new Promise(() => {}),
  setSession: () => {},
});

const AuthProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const token = localStorage.getItem(AUTH_TOKEN);
  const user = queryClient.getQueryData("me") as Me;

  const clearSession = useCallback(() => {
    if (axios.defaults?.headers?.common?.["Authorization"])
      axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem(AUTH_TOKEN);
    queryClient.setQueryData("me", null);
    navigate("/");
  }, [navigate, queryClient]);

  const setSession = useCallback(
    ({ user, token }: ISetSession) => {
      if (token) {
        localStorage.setItem(AUTH_TOKEN, token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        queryClient.setQueryData("me", user);
        toast({
          title: "Successfully authenticated",
          status: "success",
        });
      }
    },
    [toast, queryClient]
  );

  const login = useMutation(
    async (credentials: ICredentials) => {
      clearSession();
      const res = await axios.post("/account/login", credentials);
      return res.data;
    },
    {
      retry: false,
      onSuccess: (res) => {
        setSession(res?.data);
      },
      onError: (error: any) => {
        const response: ILoginErrorResponse = error?.response?.data;
        const serverError = response.message || response.error;
        const networkError = error instanceof Error && error?.message;
        clearSession();
        toast({
          title: serverError || networkError || "Login error",
          status: "error",
        });
      },
    }
  );

  const logout = useMutation<ILogoutResponse>(
    async () => {
      const res = await axios.get("/account/logout");
      return res.data;
    },
    {
      retry: false,
      onSuccess: (res) => {
        toast({
          title: res?.message || "Successfully logged out",
          status: "info",
        });
        clearSession();
      },
      onError: (error: any) => {
        const serverError = error?.response?.data?.error;
        const networkError = error instanceof Error && error?.message;
        toast({
          title: serverError || networkError || "Logout error",
          status: "error",
        });
      },
    }
  );

  const requestPasswordReset = useMutation(
    async (email: string) => {
      const res = await axios.post("/account/request-password-reset", {
        email,
      });
      return res.data;
    },
    {
      retry: false,
      onSuccess: (res: any) => {
        toast({
          title: res?.message,
          status: "info",
        });
        navigate("/");
      },
    }
  );

  const passwordReset = useMutation(
    async ({ uuid, token, newPassword }: IApiResetPassword) => {
      const res = await axios.post("/account/reset-password", {
        uuid,
        token,
        password: newPassword,
        confirm_password: newPassword,
      });
      return res.data;
    },
    {
      retry: false,
      onSuccess: (res: any) => {
        toast({
          title: res?.message || "Password has been reset",
          status: "info",
        });
        navigate("/");
      },
      onError: (res: any) => {
        const error = res?.response?.data;
        toast({
          title: error?.message || "Unable to update password",
          status: "error",
        });
      },
    }
  );

  const inviteSignup = useMutation(
    async ({ uuid, token, newPassword }: IApiResetPassword) => {
      const res = await axios.post("/account/invite-signup", {
        uuid,
        token,
        password: newPassword,
        confirm_password: newPassword,
      });
      return res.data;
    },
    {
      retry: false,
      onSuccess: (res: any) => {
        toast({
          title: res?.message || "Password has been reset",
          status: "info",
        });
        navigate("/");
      },
      onError: (res: any) => {
        const error = res?.response?.data;
        toast({
          title: error?.message || "Unable to update password",
          status: "error",
        });
      },
    }
  );

  const { mutate: reauthFn, ...reauth } = useMutation(
    async (token: string) => {
      const { data } = await axios.get("/account/reauth", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    {
      onSuccess: (res) => {
        setSession(res.data);
      },
    }
  );

  useEffect(() => {
    if (token && !user) {
      reauthFn(token);
    }
  }, [token, reauthFn, user]);

  const status: TAuthStatus = user
    ? "is_authenticated"
    : login.isLoading
    ? "is_authenticating"
    : reauth.isLoading
    ? "is_reauthenticating"
    : "not_authenticated";

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        login: (credentials: ICredentials) => login.mutateAsync(credentials),
        logout: logout.mutateAsync,
        requestPasswordReset: requestPasswordReset.mutateAsync,
        passwordReset: passwordReset.mutateAsync,
        inviteSignup: inviteSignup.mutateAsync,
        setSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
