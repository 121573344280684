const blackBtn = {
  bg: "black",
  color: "white",
  _hover: {
    bg: "gray.700",
  },
  _active: {
    bg: "gray.800",
  },
};

const linkBtn = {
  textTransform: "none",
  _hover: {
    bg: "gray.50",
  },
  _active: {
    bg: "gray.100",
  },
};

const simpleBtn = {
  minH: "32px",
  textTransform: "none",
  color: "gray.500",
  padding: 0,
  _hover: {
    color: "gray.700",
    textDecoration: "underline",
  },
  _active: {
    color: "gray.900",
    textDecoration: "underline",
  },
};

const Button = {
  baseStyle: {
    minH: "48px",
    pt: 0,
    textTransform: "uppercase",
    borderRadius: 32,
    lineHeight: "normal",
  },
  variants: {
    black: blackBtn,
    link: linkBtn,
    simple: simpleBtn,
  },
  defaultProps: {
    size: "xs",
  },
};

export default Button;
