import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import "react-day-picker/lib/style.css";

import Fonts from "./fonts";
import styles from "./styles";
import colors from "./colors";
import components from "./components";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles,
  colors,
  components,
  shadows: {
    md: "0 0 6px 0 rgba(0,0,0,0.12)",
  },
  fonts: {
    heading: "Spartan",
    body: "Spartan",
  },
});

export default function Theme({ children }: { children: JSX.Element }) {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
}
