import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, state }: { pathname: string; state: any } = useLocation();

  useEffect(() => {
    if (state?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [pathname, state]);

  return null;
}
