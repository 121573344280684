const Menu = {
  parts: ["list", "item"],

  baseStyle: {
    list: {
      borderRadius: 0,
      py: 0,
      border: "none",
    },
    item: {
      _hover: { bg: "gray.100" },
      _focus: { bg: "gray.100" },
    },
  },
};

export default Menu;
