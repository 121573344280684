export interface IFormInputs {
  email: string;
  password: string;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  mobile_number?: string;
  location?: string;
  pending: 1;
  user_type: EUserRole;
  client_id: number;
}

export type TAuthStatus =
  | "not_authenticated"
  | "is_reauthenticating"
  | "is_authenticating"
  | "is_authenticated";

export interface ICredentials {
  email: string;
  password: string;
}

export enum EUserRole {
  "admin" = 1,
  "team_leader" = 2,
  "consultant" = 3,
  "staff" = 4,
  "client" = 5,
  "senior_consultant" = 6,
}

export type ILoginResponse = {
  success: boolean;
  message: string;
  data: {
    user: IUser;
    token: string;
  };
};
export type ILoginErrorResponse = {
  message?: string;
  error?: string;
  success: false;
};

export type ILogoutResponse = {
  success: boolean;
  message: string;
};
