import { EUserRole, IUser } from "lib/types/auth";

export const staff_token = "staff.123.321";
export const admin_token = "admin.456.654";
export const client_token = "client.789.987";

export const staffUser: IUser = {
  id: 7,
  location: "Newcastle",
  name: "Test Staff",
  user_type: EUserRole.staff,
  created_at: new Date(2017, 1, 1).toISOString(),
  updated_at: new Date(2018, 1, 1).toISOString(),
  pending: 1,
  email: "staff@example.com",
  client_id: 2,
};
export const adminUser: IUser = {
  id: 3,
  location: "Newcastle",
  name: "Test Admin",
  user_type: EUserRole.admin,
  created_at: new Date(2017, 1, 1).toISOString(),
  updated_at: new Date(2018, 1, 1).toISOString(),
  pending: 1,
  email: "admin@example.com",
  client_id: 3,
};
export const clientUser: IUser = {
  id: 5,
  location: "Newcastle",
  name: "Test Client",
  user_type: EUserRole.client,
  created_at: new Date(2017, 1, 1).toISOString(),
  updated_at: new Date(2018, 1, 1).toISOString(),
  pending: 1,
  email: "client@example.com",
  client_id: 4,
};
