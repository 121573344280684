import Button from "./buttton";
import Stat from "./stat";
import Heading from "./heading";
import Text from "./text";
import Form from "./form";
import Menu from "./menu";
import Tabs from "./tabs";
import Table from "./table";

const components = {
  Button,
  Heading,
  Text,
  Stat,
  Menu,
  Tabs,
  Table,
  Spinner: {
    baseStyle: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    defaultProps: {
      size: "xl",
    },
  },
  ...Form,
};
export default components;
