import { rest } from "msw";

import {
  AdminStats,
  AdminShiftBreakdown,
  AdminChargesProfitBreakdown,
} from "lib/types/admin";

const adminStats = rest.get("/admin/stats", async (req: any, res, ctx) => {
  const response: AdminStats = {
    data: {
      number_of_hours: { label: "No. of Hours", value: 13 },
      shifts_booked: { label: "Shifts Booked", value: 13 },
      homes_supplied: { label: "Homes Supplied", value: 13 },
      new_registrations: { label: "New Registrations", value: 13 },
      candidates_placed: { label: "Candidates Placed", value: 13 },
    },
  };
  return res(ctx.status(200), ctx.json(response));
});

const adminShifts = rest.get("/admin/shifts_breakdown", async (_, res, ctx) => {
  const response: AdminShiftBreakdown = {
    data: {
      total: {
        nurse: 13,
        senior_carer: 13,
        healthcare_assistant: 22,
        child_support_worker: 13,
      },
      unfilled: {
        nurse: 1,
        senior_carer: 2,
        healthcare_assistant: 3,
        child_support_worker: 3,
      },
      cancelled: {
        nurse: 1,
        senior_carer: 2,
        healthcare_assistant: 0,
        child_support_worker: 1,
      },
    },
  };
  return res(ctx.status(200), ctx.json(response));
});

const adminCharges = rest.get(
  "/admin/charges_and_profit_breakdown",
  async (_, res, ctx) => {
    const response: AdminChargesProfitBreakdown = {
      data: {
        total_candidate_pay: "10,670.89",
        total_candidate_charge: "4,215.17",
        average_client_charge_per_shift: "46.00",
        total_profit_margin_after_employee_charges: "1250",
        average_profit_margin_per_booking: "7.00",
        profit_margin: "23%",
        profit_margin_per_consultant: "2%",
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

export const adminHandlers = [adminStats, adminShifts, adminCharges];
