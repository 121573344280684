import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";

import ScrollToTop from "./lib/utilities/scrollToTop";
import { useAuth } from "./lib/hooks/useAuth";
import { EUserRole } from "lib/types/auth";

// Unauthenticated Views
const Login = lazy(() => import("./views/unauthenticated/login"));
const ForgottenPassword = lazy(
  () => import("./views/unauthenticated/forgottenPassword")
);
const ResetPassword = lazy(
  () => import("./views/unauthenticated/resetPassword")
);
const InviteSignup = lazy(() => import("./views/unauthenticated/inviteSignup"));
const RateCardApproval = lazy(
  async () => import("./views/unauthenticated/RateCardApproval")
);

// Authenticated Views
const ClientView = lazy(() => import("views/client"));
const StaffView = lazy(() => import("views/staff"));
const StandbyStaffView = lazy(() => import("views/internal"));

export default function App() {
  const { status } = useAuth();

  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Spinner />}>
        {status === "is_reauthenticating" ? <Spinner /> : null}
        {status === "is_authenticated" ? <Authenticated /> : null}
        {status === "not_authenticated" || status === "is_authenticating" ? (
          <Unauthenticated />
        ) : null}
      </Suspense>
    </>
  );
}

export function Authenticated() {
  const { user } = useAuth();

  if (user?.user_type === EUserRole.staff)
    return (
      <Suspense fallback={<Spinner />}>
        <StaffView />
      </Suspense>
    );

  if (user?.user_type === EUserRole.client)
    return (
      <Suspense fallback={<Spinner />}>
        <ClientView />
      </Suspense>
    );

  // standby staff
  const isStandbyStaff =
    user?.user_type === EUserRole.admin ||
    user?.user_type === EUserRole.consultant ||
    user?.user_type === EUserRole.team_leader ||
    user?.user_type === EUserRole.senior_consultant;
  if (isStandbyStaff)
    return (
      <Suspense fallback={<Spinner />}>
        <StandbyStaffView />
      </Suspense>
    );

  return null;
}

export function Unauthenticated() {
  const element = useRoutes([
    { path: "/", element: <Login /> },
    {
      path: "/forgotten-password",
      element: <ForgottenPassword />,
    },
    {
      path: "/reset-password/:uuid",
      element: <ResetPassword />,
    },
    {
      path: "/invite-signup/:uuid",
      element: <InviteSignup />,
    },
    {
      path: "/rate_card_approval/:rate_email_code",
      element: <RateCardApproval />,
    },
    // fallback route
    { path: "/*", element: <Login /> },
  ]);

  return <Suspense fallback={<Spinner />}>{element}</Suspense>;
}
