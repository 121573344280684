import { rest } from "msw";

import { IDashStatsResponse } from "lib/types";

const dashboardStats = rest.get(
  "/dashboard/stats",
  async (_: any, res, ctx) => {
    const response: IDashStatsResponse = {
      data: {
        number_of_hours: { label: "No. of Hours", value: 13 },
        shifts_booked: { label: "Shifts Booked", value: 13 },
        homes_supplied: { label: "Homes Supplied", value: 13 },
        new_registrations: { label: "New Registrations", value: 13 },
        candidates_placed: { label: "Candidates Placed", value: 13 },
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

export const dashboardHandlers = [dashboardStats];
