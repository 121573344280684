import { IApiRateCardResponse } from "lib/types/rate";
import { rest } from "msw";

import { rateCards, rateCardForApproval } from "../data/rateCards";

const getClientRatesAll = rest.get(
  "/clients/:id/rate_cards",
  async (_: any, res, ctx) => {
    return res(ctx.status(200), ctx.json(rateCards));
  }
);

const getRate = rest.get(
  "/clients/:clientId/rate_cards/:rateCardId",
  async (req: any, res, ctx) => {
    const { rateCardId } = req.params;
    const rateCard = rateCards[0];
    const requestedRate: IApiRateCardResponse = { ...rateCard, id: rateCardId };
    return res(ctx.status(200), ctx.json(requestedRate));
  }
);

const getLatestRate = rest.get(
  "/clients/:clientId/get_latest_rate_card",
  async (_: any, res, ctx) => {
    const requestedRate: IApiRateCardResponse = rateCards[0];
    return res(ctx.status(200), ctx.json(requestedRate));
  }
);

const postRate = rest.post(
  "/clients/:client_id/rate_cards/create",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ success: true }));
  }
);

const updateRate = rest.post(
  "/clients/:clientId/rate_cards/edit",
  async (req, res, ctx) => {
    return res(
      ctx.status(200),

      ctx.json({
        data: "Rate successfully updated",
      })
    );
  }
);

const getRateCardForApproval = rest.get(
  "/clients/rate_card_approval/:rate_email_code",
  async (req: any, res, ctx) => {
    const { rate_email_code } = req.params;

    if (rate_email_code) {
      return res(ctx.status(200), ctx.json(rateCardForApproval));
    }

    return res(ctx.status(404));
  }
);

const approveRateCard = rest.post(
  "/clients/rate_card_approval/:rate_email_code",
  async (req: any, res, ctx) => {
    const { rate_email_code } = req.params;

    if (rate_email_code) {
      return res(ctx.status(200), ctx.json({}));
    }

    return res(ctx.status(404));
  }
);

export const ratesHandlers = [
  getClientRatesAll,
  getRate,
  getLatestRate,
  postRate,
  updateRate,
  getRateCardForApproval,
  approveRateCard,
];
