import { TScheduleShift } from "lib/types/schedule";

export const shifts: TScheduleShift[] = [
  {
    shift_info_id: 1,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pretium porttitor magna non feugiat. Cras quis dolor interdum, ultrices orci ac, viverra lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pretium porttitor magna non feugiat. Cras quis dolor interdum, ultrices orci ac, viverra lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "NE6",
      service_name: "NE6 Care",
      service_location:
        "125 Parkside Carehome, Gosforth, Newcastle Upon Tyne, NE12 8DY",
    },
    rate: {
      id: 2,
      job_title: "Nurse",
      shift_code: "SAT - SUN (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "NE6 Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 2,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "NE6",
      service_name: "NE6 Care",
      service_location: "Newcastle",
    },
    rate: {
      id: 2,
      job_title: "Support Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "NE6 Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 3,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "pending",
    shift_start_override: "11:00",
    shift_end_override: "23:00",
    client: {
      id: 1,
      client_name: "NE6",
      service_name: "NE6 Care",
      service_location: "Newcastle",
    },
    rate: {
      id: 2,
      job_title: "Support Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "NE6 Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 4,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "declined",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 5,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 6,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 7,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 8,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 9,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
  {
    shift_info_id: 10,
    date: "2021-10-16 00:00:00",
    shift_confirmation:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, iure tempora adipisci.",

    deductions:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore sunt nihil minus, eligendi at distinctio.",
    notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil.",
    status: "accepted",
    client: {
      id: 1,
      client_name: "Avant",
      service_name: "Avant Care",
      service_location: "London",
    },
    rate: {
      id: 2,
      job_title: "Care Worker",
      shift_code: "MON - FRI (08:00-20:00)",
      pay_rate: "20",
      charge_rate: "40",
    },
    rate_card: {
      id: 3,
      title: "Avant Rate Card 2021",
      agreed: 1,
    },
    staff: {
      id: 1,
      user_type: 4,
      client_id: 1,
      status: "active",
      first_name: "Test",
      last_name: "McTest",
      email: "test@email.com",
    },
  },
];
