import { authHandlers } from "./auth";
import { notificationHandlers } from "./notifications";
import { staffHandlers } from "./staff";
import { consultantHandlers } from "./consultants";
import { clientHandlers } from "./client";
import { notesHandlers } from "./notes";
import { mediaHandlers } from "./media";
import { ratesHandlers } from "./rateCards";
import { shiftHandlers } from "./shifts";
import { dashboardHandlers } from "./dashboard";
import { adminHandlers } from "./admin";

const handlers = [
  ...authHandlers,
  ...notificationHandlers,
  ...staffHandlers,
  ...consultantHandlers,
  ...clientHandlers,
  ...notesHandlers,
  ...mediaHandlers,
  ...ratesHandlers,
  ...shiftHandlers,
  ...dashboardHandlers,
  ...adminHandlers,
];

export default handlers;
