import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorMessage = error.response?.data?.message + "";
    const isUnauthenticated = errorMessage
      .toLowerCase()
      .includes("unauthenticated");

    if (isUnauthenticated) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

// use mock when not in production
// or env says to use mock (netlify)
const useMock =
  process.env.NODE_ENV === "test" || process.env.REACT_APP_USE_MOCK;

// set api url if not using mock
axios.defaults.baseURL = useMock ? "" : process.env.REACT_APP_API;

// start service worker if using mock
if (useMock) {
  const { worker } = require("./mock_api/browser");
  worker.start();
}
